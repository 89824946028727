/* Default styles for larger screens */
.information-page {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }
  
  .information-content {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .information-content ul {
    text-align: left;
    padding-left: 100px;
  }

  .information-content img {
    width: 300px; /* Set your desired width */
    height: auto; /* Maintain aspect ratio */
  }

  .information-content-en {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .information-content-en ul {
    text-align: left;
    padding-left: 100px;
  }

  .information-content-en img {
    width: 350px; /* Set your desired width */
    height: auto; /* Maintain aspect ratio */
  }
  
  /* Media query for screens smaller than 768px (adjust as needed) */
  @media (max-width: 768px) {
    .information-content ul {
        padding-left: 20px;
        /* Reduce padding on smaller screens */
    }

    .information-content img {
        width: 150px; /* Set your desired width */
        height: auto; /* Maintain aspect ratio */
      }

      .information-content-en ul {
        padding-left: 20px;
        /* Reduce padding on smaller screens */
    }

    .information-content-en img {
        width: 150px; /* Set your desired width */
        height: auto; /* Maintain aspect ratio */
      }
  }
  