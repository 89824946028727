/* Navbar container */
.navbar {
    background-color: #333;
    color: #fff;
    padding: 10px;
    height: 100px;
    margin-bottom: 30px;
  }
  @media (max-width: 768px) {
    .navbar {
        margin-bottom: 10px;
    }
}

  
  
  /* Title */
  .navbar h1 {
    text-align: center;

    font-size: 24px;
    margin: 0;
  }
  
  
  /* Menu button */
  .navbar button {


    margin-top: 40px;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
  }
  
  /* Dropdown menu */
  .navbar ul {
    text-align: center;

    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .navbar ul li {
    display: inline-block;
    position: relative;
  }
  


  .navbar ul ul {
    display: none;
    position: absolute;
    top: 100%;
    background-color: #333;
    padding: 10px;
    height: auto; /* Modifier la valeur en fonction de vos besoins */
    width: 250px;
    left: 0; /* Ajouter cette propriété */
    transform: translateX(-20%); /* Ajouter cette propriété */
  }
  
  .navbar ul li:hover > ul {
    display: block;
  }
  
  .navbar ul ul li {
    
    width: 100%;
  }
  
  .navbar ul ul a {
    color: #fff;
    display:block;
    padding: 5px 0;
    text-decoration: none;
  }
  
  .navbar ul ul a:hover {
    background-color: #555;
  }
  
  /* Language buttons */
  .navbar ul li:last-child button {
    margin-right: 0;
  }
  

  .navbar select {
    margin-left: 5px;
    font-size: 14px;
    border-radius: 10px; /* Arrondir les bords */
  }
  
  /* Styles pour les options de la liste déroulante */
  .navbar select option {
    background-color: #fff;
    color: #333;

  }
  
  /* Styles pour l'option sélectionnée */
  .navbar select option:checked {
    font-weight: bold;
  }
  

  @media (max-width: 768px) {

    .navbar h1 {
      text-align: center;
  
      font-size: 20px;
      margin: 0;
    }
    .navbar button {

      margin-top: 40px;
      background: none;
      border: none;
      color: #fff;
      cursor: pointer;
      font-size: 10px;
      margin-right: 0%;
    }

    .navbar ul li {
      font-size: 10px;
      display: inline-block;
      position: relative;
    }

    .navbar select {
      margin-left: 5px;
      font-size: 10px;
      border-radius: 10px; /* Arrondir les bords */
    }
  
  
  }