/* App.css */

.App{
  width: 40%;
  height: 40%;
  max-height: 70vh!important; 
  min-height: 70vh;
}




  .GuessDay {
      margin: 0 auto;
      padding: 20px;
      text-align: center;
      background-color: #f8f8f8;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      width: 40%;
      height: 40%;
      max-height: 70vh!important; 
      min-height: 70vh;
    
    }

    @media (max-width: 768px) {
      .GuessDay {
          max-height: 80vh!important;
          margin-bottom: 100px;
      }
  }
  
  h1 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 18px;
    margin-bottom: 0px;
  }
  
  .message {
    font-size: 20px;
    margin: 20px 0;
    padding: 10px;
    border-radius: 4px;
  }


  .result {
    
    height: 20px;
  }
  
  .correct {
    color: #4caf50;
    background-color: #dff0d8;
  }
  
  .incorrect {
    color: #ff0000;
    background-color: #f2dede;
  }
  
  .hint {
    font-size: 16px;
    /* margin-bottom: 10px; */
    color: #666;
  }
  
  form {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  input[type="text"] {
    padding: 10px;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
  
  .guessbutton {
    padding: 10px 20px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
  }
  
  .tipsbutton {
    padding: 10px 20px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    outline: none;


    

  }
  
  img{
    height: 80px;
    padding:10px;
  
  }
  
  .logo-nationality{
    position: relative;
    top: 20px;
    height: 30px;
  }
  
  p.nationalite {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .column-container {
    margin-top: 0 ;
  }
  
  
  